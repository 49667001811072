import React, { useEffect, useState } from 'react'
import './App.scss';
import AnimatePage from './Components/AnimatePage/AnimatePage';
import Header from './Components/Header/Header';
import FrontPage from './pages/FrontPage';
import Preloader from './Components/Preloader/Preloader';
import AnimatedBg from './Components/AnimatedBg/AnimatedBg';
import { useMediaQuery } from 'react-responsive'
import './i18n';


function App() {
  const [headerSize, setHeaderSize] = useState(0);
  const [updateSquircle, setUpdateSquircle] = useState(true)
  const isTablet = useMediaQuery({ query: '(max-width: 1199px)' })
  const isMac = useMediaQuery({ query: '(min-width: 2000px)' })
  // Функция анимации перехода по страницам
  const animateFunc = (block) => {
    block.classList.add('active')
    setTimeout(() => {
      block.classList.remove('active')
    }, 1300);
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  }, []);

  // задаем headerSize при рендере или ресайзе

  useEffect(() => {
    if (isTablet) {
      setHeaderSize(80)
    } else if (isMac) {
      setHeaderSize(60)
    } else {
      setHeaderSize(140)
    }
  }, [isTablet, isMac]);

  // Анимация перехода по страницам

  useEffect(() => {
    const animatePage = document.querySelector('.animate_page')
    const animBtn = document.querySelectorAll('.anim_page_btn')
    const logoBtn = document.querySelector('header .logo')

    // Анимация перехода по клику на ссылки
    animBtn.forEach(item => {
      item.addEventListener('click', function () {
        animateFunc(animatePage)
      })
    })

    // Анимация перехода по клику на лого в шапке
    logoBtn.addEventListener('click', function () {
      if (document.querySelector('.modal_product').classList.contains('active')) {
        animateFunc(animatePage)
      }
    })

    return () => {
      animBtn.forEach(item => {
        item.removeEventListener('click', function () {
          animateFunc(animatePage)
        })
      })
      logoBtn.removeEventListener('click', function () {
        if (document.querySelector('.modal_product').classList.contains('active')) {
          animateFunc(animatePage)
        }
      })
    }
  }, []);

  // Анимация скролла к якорю

  useEffect(() => {

    const animatePage = document.querySelector('.animate_page')
    const smoothScroll = () => {
      const links = document.querySelectorAll('a[href^="#"]');

      links.forEach(link => {
        link.addEventListener("click", function (e) {
          e.preventDefault();

          if (document.querySelector('.modal_product').classList.contains('active')) {
            animateFunc(animatePage)
          }

          const targetId = this.getAttribute("href").substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            const rect = targetElement.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offsetTop = rect.top + scrollTop - headerSize;

            window.scrollTo({
              top: offsetTop,
              behavior: "smooth",
            });
          }
        });
      });
    };

    smoothScroll();
  }, [headerSize]);

  return (
    <main>
      <Preloader />
      <Header setUpdateSquircle={setUpdateSquircle} />
      <FrontPage updateSquircle={updateSquircle} />
      <AnimatedBg />
      <AnimatePage />
    </main>
  );
}

export default App;
