import './HeartsMobile.scss'
import { useMediaQuery } from 'react-responsive'

function HeartsMobile({ children, color }) {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
   return (
      <div className='hearts_mobile'>
         {isMobile ? (
            color === 'yellow' ? (
               <div className="hearts_mobile_block yellow"></div>
            ) : (
               <div className="hearts_mobile_block red"></div>
            )
         ) : (
            <></>
         )}

         {children}
      </div>

   );
}

export default HeartsMobile;