import React, { useEffect, useState } from 'react'
import Hearts from "../Components/Hearts/Hearts";
import FrontHead from "../Components/Front/FrontHead/FrontHead";
import FrontNothing from "../Components/Front/FrontNothing/FrontNothing";
import FrontReviews from "../Components/Front/FrontReviews/FrontReviews";
import FrontTry from "../Components/Front/FrontTry/FrontTry";
import FrontVegan from "../Components/Front/FrontVegan/FrontVegan";
import Footer from '../Components/Footer/Footer';
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ModalProduct from '../Components/ModalProduct/ModalProduct';
import HeartsMobile from '../Components/Hearts/HeartsMobile/HeartsMobile';


gsap.registerPlugin(ScrollTrigger);



function FrontPage({ updateSquircle }) {
   const [modalData, setModalData] = useState(null);

   useEffect(() => {
      let sectionArray = gsap.utils.toArray('section')
      sectionArray.forEach(item => {
         ScrollTrigger.create({
            trigger: item,
            start: "top center",
            end: "top center",
            onEnter: () => {
               let bgColor = item.dataset.bg
               if (bgColor !== undefined && bgColor !== '') {
                  gsap.to(document.querySelector('.animated_bg'), {
                     background: bgColor,
                     duration: .5,
                     ease: 'none',
                  })
               } else {
                  gsap.to(document.querySelector('.animated_bg'), {
                     background: '#00B7D6',
                     duration: .5,
                     ease: 'none',
                  })
               }
               if (item.dataset.heart === 'true') {
                  gsap.to(document.querySelector('.hearts_block'), {
                     fill: '#FF8CB0',
                     duration: .5,
                     ease: 'none',
                  })
               } else if (item.dataset.heart === 'false') {
                  gsap.to(document.querySelector('.hearts_block'), {
                     fill: '#D9C233',
                     duration: .5,
                     ease: 'none',
                  })
               }
            },
         });
         ScrollTrigger.create({
            trigger: item,
            start: "bottom center",
            end: "bottom center",
            onEnterBack: () => {
               let bgColor = item.dataset.bg
               if (bgColor !== undefined && bgColor !== '') {
                  gsap.to(document.querySelector('.animated_bg'), {
                     background: bgColor,
                     duration: .5,
                     ease: 'none',
                  })
               } else {
                  gsap.to(document.querySelector('.animated_bg'), {
                     background: '#00B7D6',
                     duration: .5,
                     ease: 'none',
                  })
               }

               if (item.dataset.heart === 'true') {
                  gsap.to(document.querySelector('.hearts_block'), {
                     fill: '#FF8CB0',
                     duration: .5,
                     ease: 'none',
                  })
               } else if (item.dataset.heart === 'false') {
                  gsap.to(document.querySelector('.hearts_block'), {
                     fill: '#D9C233',
                     duration: .5,
                     ease: 'none',
                  })
               }
            },
         });
      })
   }, []);

   return (
      <>
         <FrontHead setModalData={setModalData} />
         <Hearts>
            <HeartsMobile color={'yellow'}>
               <FrontNothing updateSquircle={updateSquircle} />
               <FrontVegan />
            </HeartsMobile>
            <HeartsMobile color={'red'}>
               <FrontReviews updateSquircle={updateSquircle} />
               <FrontTry />
               <Footer />
            </HeartsMobile>
         </Hearts>
         <ModalProduct modalData={modalData} setModalData={setModalData} />
      </>
   );
}

export default FrontPage;