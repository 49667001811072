import AnimateText from '../../../utilities/AnimateText';
import './FrontNothing.scss'
import Marquee from "react-fast-marquee";
import { Squircle } from "@squircle-js/react";
import { useTranslation } from 'react-i18next';

function FrontNothing({ updateSquircle }) {

   const list = [
      {
         index: 1,
         title: 'без добавления растительных масел',
      },
      {
         index: 2,
         title: 'без консервантов и искусственных пищевых красителей'
      },
      {
         index: 3,
         title: 'минимальное количество компонентов'
      }
   ]

   // Перевод их json
   const { t } = useTranslation();

   return (
      <section className='front_nothing'>
         <div className="container">
            <div className="front_nothing__head">
               <AnimateText
                  containerClass={'front_nothing__head'}
                  textClass={'.front_nothing__head h2 b'}
                  trigger={'.front_nothing__head'}
                  colorStart={'transparent'}
                  colorEnd={'#FFF'}
               >
                  <h2 dangerouslySetInnerHTML={{ __html: t('nothingBlock.заголовок') }} />
                  <div className="text" dangerouslySetInnerHTML={{ __html: t('nothingBlock.текст') }} />
               </AnimateText>

            </div>
            <div className="nothing_wrap">
               <Marquee speed={'60'}>
                  <div className="nothing_wrap_list">
                     {[...list, ...list, ...list, ...list].map((item, index) => (
                        <Squircle
                           cornerRadius={20}
                           cornerSmoothing={1}
                           className='item'
                           key={index}
                           asChild={updateSquircle ? true : false}
                        >
                           <div>
                              {t(`nothingBlock.nothingBlockList.${item.index}`)}
                           </div>
                        </Squircle>
                     ))}
                  </div>
               </Marquee>
            </div>
         </div>
      </section>
   );
}

export default FrontNothing;


