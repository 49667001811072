import './Hearts.scss'
import HeartsItem from './HeartsItem';
import { useMediaQuery } from 'react-responsive'

function Hearts({ children }) {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
   return (
      <div className="hearts_block_wrap">
         {isMobile ? ('') : (
            <div className="hearts_block">
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
               <HeartsItem />
            </div>
         )}
         {children}
      </div>
   );
}

export default Hearts;