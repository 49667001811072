import React, { useEffect, useRef, useState } from 'react'
import './FrontReviews.scss'
import MasonryBlock from './MasonryBlock';
import SwiperReviews from './SwiperReviews';
import { useMediaQuery } from 'react-responsive'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AnimateText from '../../../utilities/AnimateText';
import { Squircle } from "@squircle-js/react";
import { CheckWebp } from '../../../utilities/CheckWebp'
import SwiperRating from './SwiperRating';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

function FrontReviews({ updateSquircle }) {
   const [webPSupported, setWebPSupported] = useState(true);
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' })
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
   const bgRef = useRef(null)

   // Перевод их json
   const { t } = useTranslation();

   const listReviews = [
      {
         index: 1,
         peopleName: 'Ева78',
         peopleText: 'Конфеты понравились всем. Они необычные, почти несладкие, с натуральным вкусом без какой-либо химии. Прекрасный перекус в течение дня, так как не приторные и желания запивать их чаем не возникает. Можно кушать их сами по себе.У меня вкус конфет вызвал полный восторг. Я давно не пробовала что-то подобное.Очень понравились, хочется еще купить, тем более что производитель предлагает и конфеты с какао.',
         logo: 'otzovik',
      },
      {
         index: 2,
         peopleName: 'Татьяна',
         peopleText: 'Почти не сладкие, понравились',
         logo: 'ozon',
      },
      {
         index: 3,
         peopleName: 'AlyShipa',
         peopleText: 'Вкус у этих конфет просто великолепный! Полностью натуральный состав и это чувствуется, ведь там ни капли химии. Конфеты мягкие и просто таят во рту. Если Вы любите кокос так, как люблю его я, то эти конфеты определенно придутся Вам по вкусу.',
         logo: 'otzovik',
      },
      {
         index: 4,
         peopleName: 'Яна ',
         peopleText: 'Восторг, очень вкусные, в меру сладкие, с приятной легкой кислинкой манго. Это именно манго, а не его вкус- однозначно. Прям находка для меня. Спасибо, буду брать) осень вкусные. отлично подходтт для подарка',
         logo: 'ozon',
      },
      {
         index: 5,
         peopleName: 'Алексей',
         peopleText: 'Отличные конфеты, чувствуешь вкус кокоса, нет приторного сладкого вкуса как у большинства кокосовых конфет.',
         logo: 'ozon',
      },
      {
         index: 6,
         peopleName: '',
         peopleText: 'Неожиданно вкусные .нежный кокос 🥥 ,при этом в меру сладкие.Заказал 🥭,рекомендую к покупке',
         logo: 'wb',
      },
      {
         index: 7,
         peopleName: '',
         peopleText: 'Самые вкусные из всех вариантов. Хороший состав для тех, кто на ЗОЖ.',
         logo: 'ozon',
      },

      {
         index: 8,
         peopleName: 'УменяЛапки',
         peopleText: 'Конфетки улетают мгновенно, они действительно вкусные. Сладости в них в меру, они не приторные. Удобно держать их где-то под рукой, брать с собой, и если хочется сладкого, тут же устранять эту потребность парой конфеток.',
         logo: 'otzovik',
      },
      {
         index: 9,
         peopleName: '',
         peopleText: 'Ребенку аллергику подошли. Любит вся семья.',
         logo: 'cross',
      },
      {
         index: 10,
         peopleName: 'Патима Р.',
         peopleText: 'Вкусные конфетки. В меры сладкие. Приятное послевкусие. Но мало 😊',
         logo: 'ozon',
      },
      {
         index: 11,
         peopleName: '',
         peopleText: 'Очень вкусные,ребенок обожает,который кокос раньше не любил!теперь заменяем шоколадные вредные конфеты коконессой!',
         logo: 'cross',
      },
      {
         index: 12,
         peopleName: 'Елена',
         peopleText: 'Прекрасная замена сахарным конфетам',
         logo: 'ozon',
      },
      {
         index: 13,
         peopleName: '',
         peopleText: 'Натуральный кокосовый вкус, не приторный, отличный заменитель шоколадных конфет и прочих вредных сладостей',
         logo: 'cross',
      },
      {
         index: 14,
         peopleName: '',
         peopleText: 'Очень вкусный продукт. Не содержит сахара, соли, красителей, консервантов, усилителей вкуса. Содержит кокос и манго - только полезные натуральные ингредиенты. Мягкие. Большой срок годности. Хорошо упакованы, чтобы не мялись. Сделано в России. Удобно брать с собой для перекуса вне дома. Подходит и для детей, не испортит их зубки.',
         logo: 'sber',
      },
      {
         index: 15,
         peopleName: '',
         peopleText: 'Очень вкусные конфеты, с манговой кислинкой и стружкой кокоса в виде сердечек',
         logo: 'ozon',
      },
      {
         index: 16,
         peopleName: '',
         peopleText: 'Конфеты с малиной - просто бомба! Очень понравились.',
         logo: 'wb',
      },
      {
         index: 17,
         peopleName: 'Елена ',
         peopleText: 'Класс!! Вкуснотища. Главное, полезно',
         logo: 'ozon',
      },
      {
         index: 18,
         peopleName: '',
         peopleText: 'Конфеты свежие, срок годности хороший. На вкус очень хорошие. Соответствую описанию. К покупке рекомендую.',
         logo: 'wb',
      },
      {
         index: 19,
         peopleName: '',
         peopleText: 'безумно вкусные и полезные',
         logo: 'ozon',
      },
      {
         index: 20,
         peopleName: '',
         peopleText: 'Кокосовые конфеты-сердечки с сушеным манго. ❤️Безумно вкусные, натуральный состав, оторваться невозможно.',
         logo: 'irec',
      },
      {
         index: 21,
         peopleName: '',
         peopleText: 'Конфетки в форме сердечек. Мелочь, а приятно. Лично мне понравилось, что они кисловатые, без ярко выраженного сладкого вкуса. Достали уже подсластители во всех кондитерских изделиях. Судя по описанию, состав в основном натурального происхождения - очень похоже на правду. Достаточно сытные - нет желания сразу слопать всю упаковку, быстро наедаешься.',
         logo: 'ozon',
      },
      {
         index: 22,
         peopleName: 'Елизавета906',
         peopleText: 'Сами конфетки мягкие, однородные. По структуре я бы сказала, что это кокосовое пресованное пюре. Слегка сладкие, но учитывая что это все таки кокос достаточно калорийные.Как альтернатива привычным сладостям или в качестве знака внимания хороший вариант.',
         logo: 'otzovik',
      },
      {
         index: 23,
         peopleName: 'Екатерина',
         peopleText: 'Вкусные,не очень сладкие,самое то',
         logo: 'ozon',
      },
      {
         index: 24,
         peopleName: 'paleandsweet',
         peopleText: 'одни из самых любимых, покупала еще когда они во вкусвилл продавались',
         logo: 'veganrus',
      },
      {
         index: 25,
         peopleName: 'Светалана',
         peopleText: 'Оригинальный и качественный продукт. Вкусный: в меру сладкий, сытный, консистенция подходит и для "мягких" зубов. В красивой упаковке. Буду заказывать ещё.',
         logo: 'ozon',
      },
      {
         index: 26,
         peopleName: '',
         peopleText: 'Очень вкусные. Была удивлена, т.к. не люблю манго, но эти конфеты потрясающие. Вкусные, полезные, натуральные, хорошая форма и консистенция. Обязательно буду покупать ещё.',
         logo: 'ozon',
      },
   ]

   useEffect(() => {
      ScrollTrigger.create({
         trigger: bgRef.current,
         start: "top bottom",
         end: "bottom top",
         scrub: true,
         animation: gsap.to(bgRef.current, {
            backgroundPositionY: '-50%',
            ease: 'none',
         })
      });
   }, []);

   return (
      <section className='front_reviews' data-bg='#E04777' data-heart="true" id='reviews'>
         <div className="container">
            <Squircle
               cornerRadius={50}
               cornerSmoothing={1}
            >
               <AnimateText
                  textClass={'.reviews_head h2 b'}
                  trigger={'.reviews_head h2'}
                  colorStart={'transparent'}
                  colorEnd={'#FFF'}
               >
                  <div className="reviews_head" ref={bgRef} style={{ backgroundImage: !isMobile ? `url(${CheckWebp('./img/bg_rev.jpg', webPSupported, setWebPSupported)})` : `url(${CheckWebp('./img/bg_rev_mobile.jpg', webPSupported, setWebPSupported)})` }}>
                     <h2 dangerouslySetInnerHTML={{ __html: t('reviews.заголовок') }} />
                  </div>
               </AnimateText>
            </Squircle>
            {!isTablet ? (
               <>
                  <MasonryBlock listReviews={listReviews} updateSquircle={updateSquircle} />
                  <SwiperRating updateSquircle={updateSquircle} />
               </>
            ) : (
               <SwiperReviews listReviews={listReviews} updateSquircle={updateSquircle} />
            )}
         </div>
      </section >
   );
}

export default FrontReviews;


