function HeartsItem() {
   return (
      <div className="hearts_block__item">
         <svg width="680" height="590" viewBox="0 0 680 590" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.2839 322.659L339.965 590L621.644 322.66C660.501 285.781 680 237.452 680 188.991C680 140.527 660.641 92.3315 621.643 55.3192C543.929 -18.4396 417.678 -18.4396 339.964 55.3185C262.25 -18.4389 135.999 -18.4389 58.2854 55.3199C-19.4282 129.078 -19.4282 248.902 58.2839 322.659Z" fill="#D9C233" />
         </svg>
         <svg width="680" height="590" viewBox="0 0 680 590" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.2839 322.659L339.965 590L621.644 322.66C660.501 285.781 680 237.452 680 188.991C680 140.527 660.641 92.3315 621.643 55.3192C543.929 -18.4396 417.678 -18.4396 339.964 55.3185C262.25 -18.4389 135.999 -18.4389 58.2854 55.3199C-19.4282 129.078 -19.4282 248.902 58.2839 322.659Z" fill="#D9C233" />
         </svg>
      </div>
   );
}

export default HeartsItem;